import { Vue, Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { titleTemplate } from '@/helpers/meta/title-template';

@Component
export default class NewsArticles extends Vue {
  metaInfo(): MetaInfo {
    return {
      title: titleTemplate(this.$t('meta.news.title').toString()),
    };
  }

  press = [
    {
      title: 'BusinessInsider',
      logo: require('@/assets/press/businessinsider.svg'),
      url: 'https://www.businessinsider.nl/pebbles-beleggen-vastgoed-verhuur-platform/',
    },
    {
      title: 'FinancialInvestigator',
      logo: require('@/assets/press/financialinvestigator.svg'),
      url: 'https://www.financialinvestigator.nl/nl/nieuws-detailpagina/2021/02/03/Pebbles-Beleggen-in-vastgoed-voor-de-volgende-generatie-beleggers',
    },
    {
      title: 'VastgoedJournaal',
      logo: require('@/assets/press/vastgoedjournaal.png'),
      url: 'https://vastgoedjournaal.nl/news/49064/pebbles-beleggen-in-vastgoed-voor-de-volgende-generatie-beleggers',
    },
    {
      title: 'VastgoedMarkt',
      logo: require('@/assets/press/vastgoedmarkt.svg'),
      url: 'https://www.vastgoedmarkt.nl/marktontwikkeling/nieuws/2021/02/nieuw-platform-maakt-vastgoed-bereikbaar-voor-kleine-belegger-101160817',
    },
    {
      title: 'PropertyNL',
      logo: require('@/assets/press/propertynl.jpg'),
      url: 'https://propertynl.com/Nieuws/Annexum-en-Bloqhouse-lanceren-nieuw-beleggingsplatform/9d16145a-48b0-44db-bb05-26e34cdf19a2',
    },
  ]
}
